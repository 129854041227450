export const commonSelectRowMixin = {
    data() {
      return {
        selectedItems:[],
        lastCheckedIndex: null,
      };
    },
    computed:{
    },
    methods: {
        selectAllRows(e) {
            if(e.target.checked){
              this.selectedItems = this.pageData.data.map(i => i.id );
            }else{
              this.selectedItems = []
            }
        },
        handleCheckboxClick(event, item) {
            const currentIndex = this.pageData.data.findIndex(i => i.id === item.id);
            if (this.lastCheckedIndex !== null && event.shiftKey) {
              let start = Math.min(this.lastCheckedIndex, currentIndex);
              let end = Math.max(this.lastCheckedIndex, currentIndex);
    
              for (let i = start; i <= end; i++) {
                const itemId = this.pageData.data[i].id;
                if (!this.selectedItems.includes(itemId)) {
                  this.selectedItems.push(itemId);
                }
              }
            } else {
              this.lastCheckedIndex = currentIndex;
            }
        },
    },
};